import React, { useState, useEffect } from 'react';
import ModalProposal from './ModalProposal';
import RejectionReasonModal from './RejectionReasonModal';
import ConfirmationScreen from './ConfirmationScreen';
import RejectionConfirmationScreen from './RejectionConfirmationScreen';

const API_BASE_URL = 'https://api.entradafan.com/v1';

const styles = {
    container: {
        fontFamily: 'Raleway, Arial, sans-serif',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: '#f8f9fa',
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
    },
    logo: {
        maxWidth: '180px',
        height: 'auto',
    },
    title: {
        color: '#1CA2FF',
        fontSize: '28px',
        fontWeight: '800',
        textAlign: 'center',
        marginBottom: '15px',
        textTransform: 'uppercase',
    },
    subtitle: {
        fontSize: '16px',
        textAlign: 'center',
        marginBottom: '20px',
        color: '#6c757d',
        lineHeight: '1.4',
    },
    aboveTheFold: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    timerContainer: {
        width: '100%',
        marginBottom: '15px',
    },
    timerLabel: {
        fontSize: '14px',
        color: '#6c757d',
        marginBottom: '5px',
    },
    timerBar: {
        width: '100%',
        height: '8px',
        backgroundColor: '#e9ecef',
        borderRadius: '4px',
        overflow: 'hidden',
    },
    timerProgress: {
        height: '100%',
        backgroundColor: '#F2575F',
        transition: 'width 1s linear',
    },
    timer: {
        color: '#F2575F',
        fontSize: '20px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: '5px',
    },
    aboveTheFoldButton: {
        backgroundColor: '#03CCB8',
        color: 'white',
        padding: '15px 30px',
        border: 'none',
        borderRadius: '50px',
        fontSize: '18px',
        fontWeight: 'bold',
        cursor: 'pointer',
        textTransform: 'uppercase',
        transition: 'all 0.3s ease',
        boxShadow: '0 4px 6px rgba(3, 204, 184, 0.3)',
        width: '100%',
        marginBottom: '10px',
    },
    clickToAccept: {
        fontSize: '14px',
        color: '#6c757d',
        textAlign: 'center',
    },
    flashingSalesBadge: {
        backgroundColor: '#FF4136',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '20px',
        fontSize: '14px',
        fontWeight: 'bold',
        animation: 'flash 1s linear infinite',
        display: 'inline-block',
        marginBottom: '10px',
    },
    scarcityAlert: {
        backgroundColor: '#FFD700',
        color: '#000',
        padding: '10px',
        borderRadius: '5px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '15px',
    },
    testimonial: {
        backgroundColor: '#E3F2FD',
        color: '#1565C0',
        padding: '15px',
        borderRadius: '8px',
        marginBottom: '20px',
        fontStyle: 'italic',
        textAlign: 'center',
    },
    notification: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#FF4136',
        color: 'white',
        padding: '15px',
        borderRadius: '5px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    closeNotification: {
        background: 'none',
        border: 'none',
        color: 'white',
        fontSize: '20px',
        cursor: 'pointer',
        marginLeft: '10px',
    },
    card: {
        background: '#fff',
        borderRadius: '8px',
        padding: '20px',
        marginBottom: '20px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    cardTitle: {
        color: '#1CA2FF',
        fontSize: '22px',
        marginBottom: '15px',
        fontWeight: 'bold',
    },
    table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: '0 8px',
    },
    th: {
        textAlign: 'left',
        padding: '8px',
        borderBottom: '1px solid #e9ecef',
        color: '#495057',
        fontWeight: '600',
        fontSize: '14px',
    },
    td: {
        padding: '8px',
        borderBottom: '1px solid #e9ecef',
        fontSize: '14px',
    },
    badge: {
        display: 'inline-block',
        padding: '3px 6px',
        borderRadius: '4px',
        backgroundColor: '#E8F5E9',
        color: '#2E7D32',
        fontSize: '12px',
        fontWeight: 'bold',
        marginLeft: '8px',
    },
    benefits: {
        marginBottom: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    benefitTitle: {
        color: '#1CA2FF',
        fontSize: '22px',
        marginBottom: '15px',
        fontWeight: 'bold',
    },
    benefitItem: {
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        color: '#495057',
    },
    checkmark: {
        color: '#03CCB8',
        marginRight: '10px',
        fontSize: '18px',
    },
    urgency: {
        color: '#F2575F',
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: '20px',
        fontSize: '16px',
        padding: '15px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    lastCallToAction: {
        backgroundColor: '#E8F5E9',
        borderRadius: '8px',
        padding: '20px',
        marginBottom: '20px',
        textAlign: 'center',
    },
    lastCallText: {
        color: '#2E7D32',
        fontSize: '18px',
        marginBottom: '15px',
        fontWeight: 'bold',
    },
    acceptButton: {
        backgroundColor: '#03CCB8',
        color: 'white',
        padding: '15px 30px',
        border: 'none',
        borderRadius: '50px',
        fontSize: '20px',
        fontWeight: 'bold',
        cursor: 'pointer',
        display: 'block',
        margin: '0 auto 10px',
        width: '100%',
        maxWidth: '300px',
        textTransform: 'uppercase',
        transition: 'all 0.3s ease',
        boxShadow: '0 4px 6px rgba(3, 204, 184, 0.4)',
    },
    rejectButton: {
        backgroundColor: '#fff',
        color: '#dc3545',
        border: '2px solid #dc3545',
        borderRadius: '25px',
        padding: '10px 20px',
        fontSize: '16px',
        cursor: 'pointer',
        display: 'block',
        margin: '20px auto',
        transition: 'all 0.3s ease',
        width: '100%',
        maxWidth: '300px',
        textAlign: 'center',
        fontWeight: 'normal',
        '&:hover': {
            backgroundColor: '#ffebee',
        }
    },
    rejectButtonText: {
        display: 'block',
        marginBottom: '5px',
        color: '#dc3545',
    },
    rejectButtonSubtext: {
        fontSize: '12px',
        color: '#dc3545',
        opacity: 0.8,
        display: 'block',
    },
    refundBanner: {
        backgroundColor: '#E8F5E9',
        borderRadius: '8px',
        padding: '15px',
        marginBottom: '10px',
        border: '1px solid #4CAF50',
    },
    refundTitle: {
        color: '#2E7D32',
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '5px',
    },
    refundText: {
        color: '#2E7D32',
        fontSize: '16px',
    },
    refundNote: {
        color: '#4CAF50',
        fontSize: '14px',
        marginTop: '10px',
        fontStyle: 'italic',
    },
    dateChangeAlert: {
        backgroundColor: '#FFD700',
        color: '#000',
        padding: '10px',
        borderRadius: '5px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '15px',
        fontSize: '16px',
    },
};

const UpgradeProposal = () => {
    const [offerData, setOfferData] = useState(null);
    const [timeLeft, setTimeLeft] = useState(0);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showRejectionConfirmation, setShowRejectionConfirmation] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [rejectionReasons, setRejectionReasons] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [currentTestimonial, setCurrentTestimonial] = useState('');
    const [notificationOpacity, setNotificationOpacity] = useState(1);

    useEffect(() => {
        document.title = "Propuesta Exclusiva - EntradaFan";                  

        // Add Clarity script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "n36arak0mx");
        `;
        document.head.appendChild(script);

        const fetchData = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get('id');

            if (!id) {
                setError("No se encontró una propuesta");
                setLoading(false);
                return;
            }

            try {
                const timestamp = new Date().getTime();
                const url = `${API_BASE_URL}/alternativetickets/${id}?ts=${timestamp}`;

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    cache: 'no-store'
                });

                if (!response.ok) {
                    if (response.status === 404) {
                        setError("No se encontró una propuesta");
                    } else {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                } else {
                    const data = await response.json();
                    setOfferData(data);
                    setRejectionReasons(data.rejection_reasons);
                    setTimeLeft(data.deadline_time);
                    setCurrentTestimonial(data.testimonial || "Gracias a EntradaFan, conseguí una propuesta increíble. ¡La mejor experiencia de mi vida!");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("Error al cargar los datos. Por favor, intenta nuevamente.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        const notificationInterval = setInterval(() => {
            const messages = [
                `¡Alerta! Hay ${Math.floor(Math.random() * 5) + 2} personas considerando esta propuesta ahora mismo.`,
                "¡Última oportunidad! Esta propuesta está a punto de expirar.",
                `Solo quedan ${Math.floor(Math.random() * 3) + 1} propuestas disponibles.`,
                "¡No pierdas tu lugar! Otros usuarios están a punto de aceptar esta propuesta."
            ];
            setNotificationMessage(messages[Math.floor(Math.random() * messages.length)]);
            setShowNotification(true);
            setNotificationOpacity(1);
            setTimeout(() => {
                setNotificationOpacity(0);
                setTimeout(() => setShowNotification(false), 500);
            }, 5000);
        }, 30000);

        return () => {
            clearInterval(notificationInterval);
        };
    }, []);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft(prevTime => prevTime > 0 ? prevTime - 1 : 0);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [timeLeft]);

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('es-AR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }).replace(',', '');
    };

    const handleAcceptClick = async () => {
        await updateProposalStatus('approved');
    };

    const handleRejectClick = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const showRejectionModal = () => {
        setIsModalOpen(false);
        setIsRejectionModalOpen(true);
    };

    const handleFinalReject = (rejectionData) => {
        updateProposalStatus('rejected', rejectionData.id, rejectionData.reason);
    };

    const updateProposalStatus = async (status, rejectionReasonId = null, rejectionReasonComment = null) => {
        setIsLoading(true);
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');

        try {
            let bodyData;
            if (status === 'approved') {
                bodyData = { status };
            } else {
                bodyData = {
                    status,
                    rejection_reason_id: rejectionReasonId,
                    rejection_reason_comment: rejectionReasonComment
                };
            }

            const response = await fetch(`${API_BASE_URL}/alternativetickets/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            if (status === 'approved') {
                setShowConfirmation(true);
            } else {
                setShowRejectionConfirmation(true);
            }
        } catch (error) {
            console.error("Error updating proposal status:", error);
            setError("Error al actualizar el estado de la propuesta. Por favor, intenta nuevamente.");
        } finally {
            setIsLoading(false);
        }
    };

    if (loading) return <div style={styles.container}>Cargando tu propuesta exclusiva...</div>;
    if (error) {
        return (
            <div style={styles.container}>
                <header style={styles.header}>
                    <img src="https://s3.amazonaws.com/cdn.efstatic.com/VistasNew/Contenido/logo-horizontal_2.png" alt="EntradaFan" style={styles.logo} />
                </header>
                <div style={styles.card}>
                    <h2 style={styles.cardTitle}>No hay propuestas pendientes</h2>
                    <p style={styles.td}>
                        {error === "No se encontró una propuesta"
                            ? "En este momento no tenemos una propuesta pendiente para ti. Te invitamos a seguir disfrutando de tus entradas actuales y estar atento a futuras oportunidades de mejora exclusiva."
                            : "Hubo un problema al cargar tu propuesta. Por favor, intenta nuevamente más tarde o contacta a nuestro servicio de atención al cliente si el problema persiste."}
                    </p>
                    <p style={styles.td}>
                        Gracias por tu interés en mejorar tu experiencia con EntradaFan.
                    </p>
                </div>
            </div>
        );
    }
    if (!offerData) return <div style={styles.container}>No se encontraron datos de la propuesta</div>;

    if (showConfirmation) {
        return (
            <ConfirmationScreen
                onClose={() => {
                    setShowConfirmation(false);
                }}
            />
        );
    }

    if (showRejectionConfirmation) {
        return (
            <RejectionConfirmationScreen
                onClose={() => {
                    setShowRejectionConfirmation(false);
                }}
            />
        );
    }

    const hasRefund = offerData.new_offer.offer_amount > 0;
    const isDateChange = offerData.current_offer.event_date !== offerData.new_offer.event_date;

    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <img src="https://s3.amazonaws.com/cdn.efstatic.com/VistasNew/Contenido/logo-horizontal_2.png" alt="EntradaFan" style={styles.logo} />
            </header>

            <h1 style={styles.title}>{offerData.title}</h1>
            <p style={styles.subtitle}>¡Esta oferta única expira pronto! Mejora tu experiencia ahora antes de que sea tarde.</p>

            <div style={styles.flashingSalesBadge}>¡OFERTA RELÁMPAGO!</div>

            {isDateChange && (
                <div style={styles.dateChangeAlert}>
                    ¡ATENCIÓN! Esta propuesta incluye un cambio de fecha. Por favor, revisa cuidadosamente los detalles.
                </div>
            )}

            {hasRefund && (
                <div style={styles.refundBanner}>
                    <h3 style={styles.refundTitle}>¡Increíble oportunidad de ahorro!</h3>
                    <p style={styles.refundText}>
                        Al aceptar esta propuesta exclusiva, recibirás un reintegro de ${offerData.new_offer.offer_amount} a tu método de pago original.
                    </p>
                    <p style={styles.refundNote}>
                        Mejora tu experiencia y obtén un beneficio económico inmediato. ¡No dejes pasar esta oportunidad única!
                    </p>
                </div>
            )}

            <div style={styles.aboveTheFold}>
                <div style={styles.timerContainer}>
                    <div style={styles.timerLabel}>Oferta expira en:</div>
                    <div style={styles.timerBar}>
                        <div style={{
                            ...styles.timerProgress,
                            width: `${(timeLeft / (3 * 60 * 60)) * 100}%`
                        }}></div>
                    </div>
                    <div style={styles.timer}>{formatTime(timeLeft)}</div>
                </div>
                <button style={styles.aboveTheFoldButton} onClick={handleAcceptClick}>¡ASEGURA TU PROPUESTA AHORA!</button>
                <p style={styles.clickToAccept}>Haz clic en el botón para aprovechar esta oferta exclusiva antes de que expire o alguien más la tome</p>
            </div>

            <div style={styles.scarcityAlert}>
                ¡ALERTA! Solo quedan {Math.floor(Math.random() * 5) + 1} propuestas exclusivas disponibles. ¡95% ya reservadas!
            </div>

            <div style={styles.testimonial}>
                <p>⭐⭐⭐⭐⭐ "{currentTestimonial}" - {offerData.testimonial_author || "Cliente satisfecho"}</p>
            </div>

            <div style={{ ...styles.card, borderLeft: '5px solid #1CA2FF' }}>
                <h2 style={styles.cardTitle}>TU NUEVA EXPERIENCIA MEJORADA</h2>
                <table style={styles.table}>
                    <tbody>
                        <tr>
                            <th style={styles.th}>Evento</th>
                            <td style={styles.td}><strong>{offerData.new_offer.event_name}</strong></td>
                        </tr>
                        <tr>
                            <th style={styles.th}>Nueva Fecha</th>
                            <td style={styles.td}>
                                <strong>{formatDate(offerData.new_offer.event_date)}</strong>
                                <span style={styles.badge}>{isDateChange ? "Cambio de fecha" : "¡Fecha exclusiva!"}</span>
                            </td>
                        </tr>
                        <tr>
                            <th style={styles.th}>Nueva Ubicación</th>
                            <td style={styles.td}>
                                <strong>
                                    {offerData.new_offer.location_name}
                                    {offerData.new_offer.section_name && ` - Sector ${offerData.new_offer.section_name}`}
                                    {offerData.new_offer.row && ` - Fila ${offerData.new_offer.row}`}
                                </strong>
                                <span style={styles.badge}>¡Ubicación mejorada!</span>
                            </td>
                        </tr>
                        <tr>
                            <th style={styles.th}>Venue</th>
                            <td style={styles.td}><strong>{offerData.new_offer.venue_name}</strong></td>
                        </tr>
                        <tr>
                            <th style={styles.th}>Cantidad de Entradas</th>
                            <td style={styles.td}><strong>{offerData.new_offer.tickets_quantity}</strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style={styles.benefits}>
                <h3 style={styles.benefitTitle}>Beneficios Exclusivos</h3>
                <div style={styles.benefitItem}>
                    <span style={styles.checkmark}>✓</span>
                    Recibe tus entradas a la brevedad
                </div>
                <div style={styles.benefitItem}>
                    <span style={styles.checkmark}>✓</span>
                    EntradaFan se hace cargo de cualquier costo adicional con las entradas
                </div>
                <div style={styles.benefitItem}>
                    <span style={styles.checkmark}>✓</span>
                    Soporte exclusivo
                </div>
                <div style={styles.benefitItem}>
                    <span style={styles.checkmark}>✓</span>
                    Oferta única hasta agotar stock
                </div>
                <div style={styles.benefitItem}>
                    <span style={styles.checkmark}>✓</span>
                    Posibilidad de experiencias únicas en el evento
                </div>
            </div>

            <div style={styles.urgency}>
                ¡OPORTUNIDAD ÚNICA! Sé parte del selecto grupo con acceso mejorado.
                No te arriesgues a perder esta experiencia exclusiva.
                Recuerda, si otro usuario acepta antes, podrías perder esta oportunidad.
                ¡Acepta ahora y garantiza tu lugar privilegiado!
            </div>

            <div style={{ ...styles.card, borderLeft: '5px solid #FFC24B' }}>
                <h3 style={styles.cardTitle}>Tu Reserva Actual</h3>
                <table style={styles.table}>
                    <tbody>
                        <tr>
                            <th style={styles.th}>Evento</th>
                            <td style={styles.td}>{offerData.current_offer.event_name}</td>
                        </tr>
                        <tr>
                            <th style={styles.th}>Fecha</th>
                            <td style={styles.td}>{formatDate(offerData.current_offer.event_date)}</td>
                        </tr>
                        <tr>
                            <th style={styles.th}>Ubicación</th>
                            <td style={styles.td}>
                                {offerData.current_offer.location_name}
                                {offerData.current_offer.section && ` - Sector ${offerData.current_offer.section_name}`}
                                {offerData.current_offer.row && ` - Fila ${offerData.current_offer.row}`}
                            </td>
                        </tr>
                        <tr>
                            <th style={styles.th}>Venue</th>
                            <td style={styles.td}>{offerData.current_offer.venue_name}</td>
                        </tr>
                        <tr>
                            <th style={styles.th}>Cantidad de Entradas</th>
                            <td style={styles.td}>{offerData.current_offer.tickets_quantity}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p style={styles.urgency}>
                ¡ÚLTIMA LLAMADA! Esta oferta exclusiva expira en {formatTime(timeLeft)}.
                Las propuestas están volando, ¡no pierdas la oportunidad de vivir una experiencia inolvidable!
            </p>

            <div style={styles.lastCallToAction}>
                <p style={styles.lastCallText}>
                    Esta es tu ÚLTIMA OPORTUNIDAD de vivir una experiencia increíble.
                    {hasRefund ? " ¡Y con un reintegro EXTRA que va directo a tu bolsillo!" : ""}
                    No te quedes con el "qué hubiera pasado si...". ¡Acepta ahora y crea recuerdos inolvidables!
                </p>
                <button style={styles.acceptButton} onClick={handleAcceptClick}>
                    ¡SÍ, QUIERO ESTA PROPUESTA {hasRefund ? "Y MI REINTEGRO " : ""}AHORA MISMO!
                </button>
                <p style={styles.clickToAccept}>
                    Haz clic para asegurar tu experiencia mejorada {hasRefund ? "y tu reintegro inmediato" : ""}
                </p>
            </div>

            <button style={styles.rejectButton} onClick={handleRejectClick}>
                <span style={styles.rejectButtonText}>No, gracias</span>
                <span style={styles.rejectButtonSubtext}>Mantener mi entrada actual</span>
            </button>

            <ModalProposal
                isOpen={isModalOpen}
                onClose={closeModal}
                onAccept={() => {
                    handleAcceptClick();
                    setIsModalOpen(false);
                }}
                onShowRejectionModal={showRejectionModal}
                eventDetails={offerData ? `${offerData.new_offer.tickets_quantity} Entradas mejoradas ${offerData.new_offer.location_name}${offerData.new_offer.section ? ` - Sector ${offerData.new_offer.section}` : ''}${offerData.new_offer.row ? ` - Fila ${offerData.new_offer.row}` : ''} para ${offerData.new_offer.event_name} ${formatDate(offerData.new_offer.event_date)}, ${offerData.new_offer.venue_name}` : ''}
                timeLeft={timeLeft}
            />

            <RejectionReasonModal
                isOpen={isRejectionModalOpen}
                onClose={() => setIsRejectionModalOpen(false)}
                onAccept={() => {
                    handleAcceptClick();
                    setIsRejectionModalOpen(false);
                }}
                onReject={handleFinalReject}
                eventDetails={offerData ? `${offerData.new_offer.tickets_quantity} Entradas mejoradas ${offerData.new_offer.location_name}${offerData.new_offer.section ? ` - Sector ${offerData.new_offer.section}` : ''}${offerData.new_offer.row ? ` - Fila ${offerData.new_offer.row}` : ''} para ${offerData.new_offer.event_name} ${formatDate(offerData.new_offer.event_date)}, ${offerData.new_offer.venue_name}` : ''}
                reasons={rejectionReasons}
            />

            {isLoading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999,
                }}>
                    <div style={{
                        width: '50px',
                        height: '50px',
                        border: '5px solid #f3f3f3',
                        borderTop: '5px solid #3498db',
                        borderRadius: '50%',
                        animation: 'spin 1s linear infinite',
                    }} />
                </div>
            )}

            {showNotification && (
                <div style={{
                    ...styles.notification,
                    opacity: notificationOpacity,
                    transform: `translateY(${notificationOpacity === 0 ? '20px' : '0'})`,
                }}>
                    <span>{notificationMessage}</span>
                    <button
                        style={styles.closeNotification}
                        onClick={() => {
                            setNotificationOpacity(0);
                            setTimeout(() => setShowNotification(false), 500);
                        }}
                    >
                        ×
                    </button>
                </div>
            )}
        </div>
    );
};

export default UpgradeProposal;